ul.grid-overview {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
ul.grid-overview.grid-overview-2 li {
  width: 48%;
}
ul.grid-overview.grid-overview-3 li {
  width: 98%;
}
ul.grid-overview.grid-overview-4 li {
  width: 23%;
}
ul.grid-overview.grid-overview-5 li {
  width: 18%;
}
ul.grid-overview.grid-overview-6 li {
  width: 14%;
}
ul.grid-overview li {
  display: inline-block;
  text-align: center;
  padding: 0 15px;
  width: 100%;
  font-size: 14px;
  border-right: 1px solid #e6e6e6;
}
ul.grid-overview li:last-child {
  border-right: 0;
}
ul.grid-overview li li:first-child {
  padding-left: 0;
}
ul.grid-overview li .name {
  font-size: 12px;
}
ul.grid-overview li .value {
  font-size: 14px;
  font-weight: 700;
  display: block;
}
ul.grid-overview li .value.no-value {
  opacity: 0.7;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
}
