tr.sumup {
	font-weight:  bold;
}

#logo {
	font-size : 36px;
	color : white;
	display : block;
	height: 46px;
}

#logo .glyphicon{
	position: relative;
	top: 5px;
	left:4px;
}

#user-box {
	position : relative;
	cursor : pointer;
}

#user-box img {
	height: 48px;
	display: block;
	margin: auto;
}
#user-box button {
	margin: auto;
}
#user-box .glyphicon.glyphicon-user {
	font-size : 36px;
}
#user-box #user-connection-logout  svg{
	display:none;
	position : absolute;
	top : 38px;
	left: 38px;
	font-size : 20px;
	color : red;	
}

#user-box:hover #user-connection-logout svg{
	display : block;
}
